* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: white;
    text-align: center;
}

h1, h2 {
    font-family: Lora, 'Times New Roman', Times, serif;
    font-weight: 700;
}

h1 {
    font-size: 48px;
    font-weight: 700;
}

h2 {
    font-size: 32px;
}

p {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.mainContainer {
    background-image: url(../background.jpg);
    background-size: cover;
    background-position: 50% 50%;
    background-color: rgba(129, 129, 129, 0.399);
    background-blend-mode: darken;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    h1 {
        font-size: 38px;
    }

    p {
        font-size: 18px;
    }

    .mainContainer {
        background-position: 40% 50%;
    }
}

.blurContainer {
    backdrop-filter: blur(2.5px);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: min(1000px, 80vw);
    gap: 32px;
}
